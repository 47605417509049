/* eslint no-unused-vars:off */

// import 'babel-polyfill';

// 全ページ共通処理
import common from './pages/common/common';
// トップページ
import home from './pages/home/home';
// 作品紹介
// import titleList from './pages/title-list/title-list';

common();
home();
// titleList();
