/**
 * fancybox3 を使ったポップアップ
 * http://uzurea.net/explanation-of-fancybox3/
 */

export default function () {
  $('[data-fancybox]').fancybox({
    infobar: false,
    toolbar: true,
    buttons: [
      'close',
    ],
    btnTpl: {
      close: '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}"><i class="i-arrows-times-1"></i></button>',
    },
  });
}
