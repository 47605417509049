
// 全ページ共通処理

/* eslint no-unused-vars:off */
/* eslint no-console:off */

// 画像の遅延読み込み
import lazyload from './common_lazyload';
// メディアクエリー管理
import mediaquery from './common_mediaquery';
// ユーザーエージェント判定
import check_ua from './common_check-ua';
// ページ内スクロール
import pagescroll from './common_pagescroll';
// 画面位置に応じたて data 属性変化。ページトップ戻るボタンなど
import scroll_amount from './common_scroll-amount';
// ソーシャルシェアボタン
import social_share from './common_social-share';
// ポップアップ
import popup from './common_popup';
// オーディオ再生ボタン
// import audio from './common_audio';
// メインメニュー
import menu from './common_menu';
// ページ読み込み
import progress from './common_progress';
// ページ読み込み完了
import progress_complete from './common_progress_complete';

export default function () {
  lazyload();
  mediaquery();
  check_ua();
  pagescroll();
  scroll_amount();
  social_share();
  menu();
  popup();
  // audio();
  progress().then(progress_complete);
}

