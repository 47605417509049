/**
 * 画像の遅延読み込み
 * https://qiita.com/kaneU/items/369a9972fd45223677fd
 * <img src="dummy.png" data-src="hoge.jpg" class="js-lazy">
 * data-src 属性に遅延読み込みさせたい画像を指定する。
 *
 * data-src : srcにセットされる
 * data-srcset : srcsetにセットされる
 * data-background-image : backgroundImageにセットされる
 *
 * IE に対応させるために IntersectionObserver の polyfill が必要
 * <script src="https://rawgit.com/w3c/IntersectionObserver/master/polyfill/intersection-observer.js"></script>
 */

import lozad from 'lozad';

export default function () {
  const observer = lozad('.js-lazy');
  observer.observe();
}
