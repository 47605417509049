/**
 * 画面内の位置に応じて data-scroll-amount 属性を変化させる
 * 「ページトップに戻るボタン」の表示切り替えなど
 */

/* eslint no-unused-vars:off */

import ScrollAmount from '@sygnas/scroll-amount';

export default function () {
  const scroll_amount = new ScrollAmount('.js-scroll-amount', { offset_top: 0 });
}
