/**
 * メインメニュー
 */

export default function () {
  // クリックで data-hamburger-open = "true" にする
  const $hamburger = $('.js-hamburger');

  // ハンバーガーボタンのクリックでメニューの開閉
  $hamburger.on('click', toggle_menu);

  // メニューのボタンを押しても開閉する
  // $('.c-mainmenu a.js-pagescroll').on('click', toggle_menu);

  // サブメニュー開閉
  $('.js-has-submenu').on('click', toggle_submenu);

  // メニューのアクティブ状態設定
  set_menu_active();
}

/**
 * メニューを開閉する
 * @param {Event} ev クリックイベント
 */
function toggle_menu (ev) {
  // ハンバーガーメニューの切り替え
  const $hamburger = $('.js-hamburger');
  const state = $hamburger.attr('data-hamburger-open');
  const is_open = state === 'true' ? 'false' : 'true';
  $hamburger.attr('data-hamburger-open', is_open);

  // スマホ用メニューのエフェクト
  $('.js-sp-menu').attr('data-sp-menu', is_open === 'true' ? 'open' : 'close');

  ev.preventDefault();
}

/**
 * サブメニューの開閉
 * @param {Event} ev クリックイベント
 */
function toggle_submenu (ev) {
  // メニューボタンと、兄弟要素のサブメニュー
  const $button = $(ev.currentTarget);
  const $submenu = $button.next();
  const is_opened = $button.attr('data-submenu-opened') === 'true';

  if (is_opened) {
    $button.attr('data-submenu-opened', 'false');
    $submenu.attr('data-submenu-opened', 'false');
  } else {
    $button.attr('data-submenu-opened', 'true');
    $submenu.attr('data-submenu-opened', 'true');
  }
  ev.preventDefault();
}

/**
 * メニューのアクティブ状態設定
 */
function set_menu_active () {
  const now = document.querySelector('body').getAttribute('data-menu-active');
  if (now === 'home') return;

  document.querySelector(`.c-mainmenu__link[data-menu-item="${now}"]`)
    .setAttribute('data-is-active', 'true');
}
