/**
 * トップページ
 */

// wordpress 更新情報
import wordpress from './home_wordpress';

export default function () {
  // トップページ以外は抜ける
  if ($('#page-home').length <= 0) return;

  wordpress();
}
