/**
 * カスタムスクロールバー
 */

/* eslint no-unused-vars:off */
/* eslint no-console:off */

import PerfectScrollbar from 'perfect-scrollbar';

export default function () {
  const ps = new PerfectScrollbar('.js-scrollbar');
}
